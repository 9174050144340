// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top: rem(100px);
	
	@include breakpoint(large) {
		padding-top: 0;
	}
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	width: 100%;

	@include breakpoint(large) {
		top: rem(130px);
		padding-bottom: rem(100px);	
	}


	.desktop-bar {
		display: none;
		width:100%;

		.flex .col {
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-flex-direction: row;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-flex-wrap: nowrap;
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
			-webkit-justify-content: space-between;
			-ms-flex-pack: justify;
			justify-content: space-between;
			-webkit-align-content: space-between;
			-ms-flex-line-pack: justify;
			align-content: space-between;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			padding-top: rem(30px);
			padding-bottom: rem(30px);

			.branding, nav, aside,.phone svg,.phone span {
				-webkit-order: 0;
				-ms-flex-order: 0;
				order: 0;
				-webkit-flex: 0 1 auto;
				-ms-flex: 0 1 auto;
				flex: 0 1 auto;
				-webkit-align-self: auto;
				-ms-flex-item-align: auto;
				align-self: auto;
			}

			.branding {
				float:none;
				max-width: rem(200px);
				margin-top: rem(-10px);

				@include breakpoint(giant) {
					max-width: rem(230px);
				}
			}
			aside {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.phone {
					// display: -ms-flexbox;
					// display: -webkit-flex;
					// display: flex;
					-webkit-flex-direction: row;
					-ms-flex-direction: row;
					flex-direction: row;
					-webkit-flex-wrap: nowrap;
					-ms-flex-wrap: nowrap;
					flex-wrap: nowrap;
					-webkit-justify-content: space-between;
					-ms-flex-pack: justify;
					justify-content: space-between;
					-webkit-align-content: space-between;
					-ms-flex-line-pack: justify;
					align-content: space-between;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					background: $add;
					padding:rem(12px);
					// margin-bottom: rem(5px);
					text-align: center;
					
					svg {
						display: inline-block;
						width: rem(18px);
						height: rem(18px);
						z-index: 1;
						overflow: visible;
						margin: { top: rem(2px); right: rem(5px); bottom: rem(-3px); }
						color: $primary;
						color: $light;
						-webkit-transform: rotate(100deg);
						-moz-transform: rotate(100deg);
						-o-transform: rotate(100deg);
						-ms-transform: rotate(100deg);
						transform: rotate(100deg);
						filter: progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=0, M21=0, M22=-1, DX=0, DY=0, SizingMethod='auto expand');
					}
					a,span {
						font-weight: 700;
						text-decoration: none;
						color: $light;
						font-size: rem(15px);
					}
				}

				.writeUs{
					background: $primary;
					color: $light;
					a{
						color: $light;
						font-size: rem(14px);
						font-weight: 700;
						text-decoration: none;
						padding: rem(12px) rem(12px) rem(12px) rem(40px);
						display: block;
						position: relative;

						&:before{
							content: '';
							width: 20px;
							height:20px;
							background-image: image-url('mobile-menu/emailWhite.svg');
							background-size: cover;
							background-repeat: no-repeat;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: rem(10px);

						}
					}
				}
			}
		}

		@include breakpoint(large) {
			display: block;
			position: fixed;
			background: $light;
			z-index: 500;
			top: 0;
		}
	}
	
	.teaser {
		position: relative;

		.titles {
			@include spacing-small-inner();
			height: rem(334px);
			background-image: image-url('la_sunset_small.jpg');
			background-repeat: no-repeat;
			background-size: cover;

			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-flex-direction: row;
			-ms-flex-direction: row;
			flex-direction: row;
			-webkit-flex-wrap: nowrap;
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
			-webkit-justify-content: flex-start;
			-ms-flex-pack: start;
			justify-content: flex-start;
			-webkit-align-content: center;
			-ms-flex-line-pack: center;
			align-content: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;

			@include breakpoint(small) {
				height: rem(400px);
				background-image: image-url('la_sunset_large.jpg');
			}


			@include breakpoint(large){
				background: $light;
				height: auto;
				padding-top: rem(40px);
				padding-bottom: rem(100px);
			}

			.title-box {
				-webkit-order: 0;
				-ms-flex-order: 0;
				order: 0;
				-webkit-flex: 0 1 auto;
				-ms-flex: 0 1 auto;
				flex: 0 1 auto;
				-webkit-align-self: center;
				-ms-flex-item-align: center;
				align-self: center;
				background: rgba($primary,0.8);
				width:100%;
				display: block;

				@include breakpoint(medium) {
					width: auto;
				}

				@include breakpoint(large) {
					background: $light;
				}

				.title {
					@extend .h1;
					font-size: rem(25px);
					line-height: rem(30px);
					margin: 0;
					color: $light;
					display: none;
					padding: rem($base-gap*2) rem(45px) rem($base-gap*2) rem($base-gap); 

					@include breakpoint(large) {
						font-size: rem(35px);
						line-height: rem(45px);
						padding: 0;
						margin: 0;
						color: $dark;
					}

					@include breakpoint(giant) {
						font-size: rem(50px);
						line-height: rem(60px);
					}

					&.nojs-fallback {
						display: block;
					}
				}
			}
		}
		.box-container {
			@include breakpoint(large) {
				position: absolute;
				bottom: rem(-190px);
				left: rem($base-gap);
				width: auto;
				max-width: unset;
				padding: 0;
				margin: 0;
			}
		}
		.box {
			@include spacing-small-inner();
			padding-right: rem(40px);
			text-decoration: none;
			animation-play-state: paused;
			position: relative;

			@include breakpoint(large) {
				padding: { left: rem(40px); right: rem(40px); top: rem(50px); bottom: rem(50px); }
			}
			

			&.individual-treatment {
				background: $medium;
				color: $dark;

				@include breakpoint(large) {
					margin-right: rem($base-gap);
				}

				&:hover, &:focus {
					color: $dark;
				}
				&:before {
					color: $primary;
				}
			}
			&.our-services {
				background: $add;
				color: $light;

				dd:before {
					background-image: image-url("long-arrow-right-white.svg");
				}
				&:hover, &:focus {
					color: $light;
				}
			}

			&:hover,&:focus {
				text-decoration: none;
				animation-play-state: running;
				-webkit-animation: pulse .5s;
				animation: pulse .5s;
			}

			dl {
				@extend .floatList;
				position: relative;

				dt {
					font-weight: 700;
					font-size: rem(15px);
					padding-bottom: rem($base-gap);
					z-index: 50;
				}

				dd {
					font-size: rem(20px);
					font-weight:300;
					line-height: rem(30px);
					position: relative;

					&:before {
						position: absolute;
						z-index: 100;
						content: '';
						background-image: image-url("long-arrow-right-primary.svg");
						background-size: cover;
						width: rem(30px);
						height: rem(18px);
						top: rem(-38px);
						right: rem(0px);

						@include breakpoint(medium) {
							top: unset;
							bottom: rem(5px);
							right: rem(0px);
						}
					}

					@include breakpoint(large) {
						font-size: rem(25px);
						line-height: rem(35px);

						span {
							display: block;
						}
					}
				}
			}

			dt, dd, dl {
				text-transform: uppercase;
				margin: 0;
			}
		}
	}
	.desktop-teaser {
		display: none;
		width:100%;
		height: rem(575px);
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		background-position: center top;

		@include breakpoint(large) {
			display: block;
			background-image: image-url('la_sunset_huge.jpg');
		}
		@include breakpoint(huge) {
			background-image: image-url('la_sunset_full.jpg');
		}
	}
	.branding {
		display: block;
		float: right;

		@include breakpoint(medium) {
			float: left;
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	@include spacing();

	.small-between-last {
		@include breakpoint(small-between) {
			padding-left: rem(30px);	
		}

		@include breakpoint(giant) {
			padding-left: rem(0px);				
		}
	}

	p {
		a.no-icon {
			&:before {
				display:none;
			}
		}
	}

	p {
		span.headlineContact {
			color:$dark;
			text-transform: uppercase;
		}
	}

	ul.count {
		@include breakpoint(small) {
			-webkit-column-count: 2;
			column-count: 2;		
		}

		@include breakpoint(medium) {
			-webkit-column-count: 1;
			column-count: 1;		
		}

		@include breakpoint(large) {
			-webkit-column-count: 2;
			column-count: 2;		
		}
	}

	p, ul, li, dd, dt, dl, form {
		color: #797272;
		font-size: rem(17px);
		line-height: rem(30px);
	}

	ul, dl {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}

	.index-content-first {
		h1 {
			font-size: rem(28px);
			line-height: rem(36px);
			@include breakpoint(medium) {
				font-size: rem(34px);
			    line-height: rem(45px);
			}
			@include breakpoint(large) {
				font-size: rem(29px);
				line-height: rem(34px);
				margin-top: rem(60px);
			}
			@include breakpoint(giant) {
				font-size: rem(34px);
				line-height: rem(45px);
				margin-top: rem(110px);
			}
			@include breakpoint(giant-between){
				margin-top: rem(60px);
			}
		}

		p:last-child {
			@include breakpoint(small-between) {
				margin-bottom: 0;
			}
		}


		.beach {
			
			display: flex;
			align-items: center;
			min-height: rem(500px);
			margin-top: rem(60px - 24px);
			// @include breakpoint(medium, max) {
			// 	margin-top: rem(60px - 24px);
			// }
			&.lazyLoaded {
				background-image: image-url('beach.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: bottom;
			}

			@include breakpoint(small) {
				min-height: rem(500px);
			}

			@include breakpoint(small-between) {
				min-height: rem(550px);
				margin-top: 0;
			}

			@include breakpoint(medium) {
				height: rem(600px);
			}

			@include breakpoint(large) {
				height: rem(640px);
				align-items: flex-end;
			}

			.box {
				order: 0;
				flex: 0 1 auto;
				background: rgba($primary,0.95);
				width:100%;
				display: block;

				@include breakpoint(large) {
					text-align: right;
					margin-bottom: rem(70px);
				}

				@include breakpoint(giant) {
					width:rem(520px);
				}

				@include breakpoint(giant-between) {
					position: absolute;
					right: rem(-70px);
					bottom: 0;
				}

				p {
					@extend .h1;
					font-size: rem(30px);
					margin: 0;
					padding: rem(45px) rem(40px);
					color: $light;
				}
			}
		}
	}

	.service-container {
		margin-top: rem(150px);
		margin-bottom: rem(60px);
		// @include breakpoint(small, max) {
		// 	margin-bottom: rem(60px);
		// }
		// @include breakpoint(small) {
		// 	@include spacing();
		// }

		@include breakpoint(small-between) {
			padding-top: rem(100px);
			//padding-bottom: 100px;
			position: relative;
			margin-top: rem(60px);


			&:before {
				background: #f0f0f0;
				content: '';
				width:100%;
				height:rem(333px);
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		@include breakpoint(medium) {
			margin-top: rem(100px);
			margin-bottom: rem(100px);
			padding-bottom: rem(100px);

			&:before {
				height: rem(250px);
			}
		}
		@include breakpoint (large){
			margin-top: rem(150px);
			margin-bottom: rem(150px);
		}

		.button-next,
		.button-prev {
			display: none;

			@include breakpoint(medium) {
				display: block;
			}

			@include breakpoint(giant) {
				display: none;
			}
		}

		&.swiper-container {

			.button-next,
			.button-prev {
				position: absolute;
				top: 50%;
				z-index: 50;
				cursor: pointer;
				opacity: 0.8;
				svg {
					width: rem(55px);
					height: rem(30px);
				}

				&:hover,
				&:focus {
					transform: scale(1.05);
					opacity: 1;
				}
			}

			.button-next {
				right: rem(30px);
				@include breakpoint(giant) {
					right: 12%;
				}
				@include breakpoint(giant-between) {
					right: 16%;
				}
			}

			.button-prev {
				left: rem(30px);
				transform: rotate(180deg);
				&:hover {
					transform: scale(1.05) rotate(180deg);
				}
				@include breakpoint(giant) {
					left: 12%;
				}
				@include breakpoint(giant-between) {
					left: 16%;
				}
			}

			.service:not(.swiper-slide-active) {
				@include breakpoint(large) {
					&:hover, &:focus {
						transform: scale(1.05);
					}
				}


				.inner {
					// opacity: 0.4;
					a {
						// display: none;
					}
				}
			}
		}

		
	}
	
	.services {
		$slides: 4;
		$time_per_slide: 4;
		$total_animation_time: $time_per_slide * $slides;
		width:100%;
		max-width: calc(100% - #{rem($base-gap*2)});
		height:rem(300px);
		overflow:hidden;
		position:relative;
		margin: rem(-240px) 0 rem(50px) rem($base-gap);
		//background-color: $primary;
		z-index: 10;

		@include breakpoint(tiny) {
			max-width: rem(400px);
			margin: rem(-240px) 0 rem(50px) calc((100vw - (400px + #{rem($base-gap*1)})) / 2);
		}

		@include breakpoint(small-between) {
			max-width: rem(500px);
			margin: 0 0 0 calc((100vw - (500px + #{rem($base-gap*2)})) / 2);
		}

		@include breakpoint(medium) {
			width: auto;
			max-width: unset;
			margin: 0;
			height: auto;
			overflow: visible;
			background-color: transparent;
		}

		@include breakpoint(giant) {
			margin-left: auto;
			margin-right: auto;
			max-width: rem($row-max-width);
			width: 100%;
		}

		@include breakpoint(giant-between) {
			max-width: rem($row-max-width + 150px);
		}

		@include breakpoint(huge) {
			max-width: rem($row-max-width + 400px);
		}

		.service {
			position:absolute;
			-webkit-animation:round #{$total_animation_time}s infinite;
			animation:round #{$total_animation_time}s infinite;
			opacity:1;
			padding: 0;
			transition: all 0.5s;

			&:hover,
			&:focus {
				@include breakpoint(large) {
					transform: scale(1.1);
				}
			}

			@include breakpoint(medium) {
				position: relative;
				transform: none;
				animation: none;
				opacity: 1;
				width:100%;
				max-width:rem(300px);
			}

			@include breakpoint(giant) {
				background-color: transparent;
				padding-left: rem($base-gap);
				padding-right: rem($base-gap);
				max-width: 25%;
				width: auto;
			}

			.inner {
				background-image: image-url('sprite_services.jpg');
				background-repeat: no-repeat;
				height: rem(300px);
				max-width: rem(600px);
				margin: auto;
				position: relative;
				z-index: 200;
				position: relative;
				//background-color: #d8d8d8;

				@include breakpoint(large) {
					background-image: image-url('sprite_services_full.jpg');
					max-width: rem(365px);
					height: rem(250px);
				}

				a {
					position: absolute;
					bottom: 0;
					left: 0;
					width:100%;
					background: rgba($light,0.85);
					text-decoration: none;
					color: $dark;
					z-index: 500;
					padding: rem(15px) rem(45px) rem(20px) rem(15px);
					height: auto;
					font-size: rem(20px);
					font-weight: 300;
					text-transform: uppercase;
					line-height: rem(32px);
					transition: all 1s;

					@include breakpoint(large){
						box-shadow: 1px 0px 9px -4px rgba(0,0,0,0.39);
						margin-bottom: rem(9px);
					}

					@include breakpoint(medium) {
						background: $light;
						padding: rem($base-gap);
						width: calc(100% - #{rem($base-gap*2)});
						bottom: rem(-100px);
						font-size: rem(24px);
					}

					@include breakpoint(giant) {
						font-size: rem(21px);
						width:100%;
						padding: rem(30px) rem(20px) rem(30px) rem(20px);
						margin-bottom: 0;
					}

					@include breakpoint(giant-between) {
						font-size: rem(23px);
						bottom: rem(-105px);
						padding: rem(15px) rem(60px) rem(15px) rem(25px);
					}

					@include breakpoint(huge) {
						padding: rem(40px) rem(80px) rem(40px) rem(40px);
						bottom: rem(-140px);
						font-size: rem(24px);
					}


					&:before {
						position: absolute;
						top: rem(22px);
						right: rem(15px);
						z-index: 100;
						content: '';
						background-image: image-url("long-arrow-right.svg");
						background-size: cover;
						width: rem(30px);
						height: rem(18px);

						@include breakpoint(medium) {
							top: rem(35px);
							right: rem(5px);
						}

						@include breakpoint(medium) {
							top: rem(45px);
							right: rem(15px);
						}

						@include breakpoint(giant) {
							top: rem(50px);
							right: rem(10px);
						}

						@include breakpoint(giant-between) {
							top: rem(35px);
							right: rem(20px);
						}

						@include breakpoint(huge) {
							top: rem(60px);
							right: rem(30px);
						}
					}

				}
			} 

			&.addiction-treatment .inner {
				background-position: -610px -60px;

				@include breakpoint(large) {
					background-position: -488px -30px;
				}
			}
			&.psychiatric-treatment .inner {
				background-position: -100px -40px;

				@include breakpoint(small-between) {
					background-position: -100px -50px;
				}

				@include breakpoint(large) {
					background-position: -20px -30px;
				}
			}
			&.child-adolescent .inner {
				background-position: -200px -430px;

				@include breakpoint(small) {
					background-position: -160px -420px;
				}

				@include breakpoint(small-between) {
					background-position: -100px -420px;
				}

				@include breakpoint(medium) {
					background-position: -290px -420px;
				}

				@include breakpoint(large) {
					background-position: -150px -300px;
				}

				@include breakpoint(giant) {
					background-position: -160px -300px;
				}

				@include breakpoint(giant-between) {
					background-position: -120px -300px;
				}

				@include breakpoint(huge) {
					background-position: -80px -300px;
				}
			}
			&.psychotherapy-other-services .inner {
				background-position: -680px -500px;
				
				@include breakpoint(large) {
					background-position: -500px -340px;
				}

				@include breakpoint(giant) {
					background-position: -530px -340px;
				}
			}

		}

		@keyframes round{
			25%{
				opacity:1;
			}
			40%{
				opacity:0;
			}
		}
		@-webkit-keyframes round{
			25%{
				opacity:1;
			}
			40%{
				opacity:0;
			}
		}

		@for $index from 1 to $slides + 1{
			.service:nth-child(#{$index}){
				-webkit-animation-delay:#{$total_animation_time - $time_per_slide * $index}s;
				animation-delay:#{$total_animation_time - $time_per_slide * $index}s;

				@include breakpoint(large) {
					animation-delay: unset;
				}
			}
		}
	}

	.index-content-second {
		.beach-list {	
			min-height: rem(500px);
			display: flex;
			align-items: center;
			margin-top: rem(60px - 24px);

			&.lazyLoaded {
				background-image: image-url('beach-2.jpg');
				background-repeat: no-repeat;
				background-position: right;
				background-size: cover;
			}


			@include breakpoint(small-between) {
				min-height: rem(900px);
				margin-top: 0;
			}

			@include breakpoint(large) {
				min-height: rem(800px);
			}

			// @include breakpoint(small-between) {
			// 	min-height: rem(800px);
			// }


			@include breakpoint(giant){
				min-height: rem(715px);
			}


			@include breakpoint(large) {
				align-items: flex-end;
			}


			.box {
				-webkit-order: 0;
				-ms-flex-order: 0;
				order: 0;
				-webkit-flex: 0 1 auto;
				-ms-flex: 0 1 auto;
				flex: 0 1 auto;

				background: rgba($primary,0.95);
				width:100%;
				display: block;

				padding: rem($base-gap);

				@include breakpoint(large) {
					text-align:left;
					width: rem(400px);
					margin-bottom: rem(70px);
				}

				@include breakpoint(giant-between) {
					width: rem(440px);
					position: absolute;
					left: rem(-70px);
					bottom: 0;
				}

				ul {
					margin: 0;

					li {
						font-size: rem(16px);
						font-weight: 400;
						text-transform: uppercase;
						line-height: rem(35px);
						margin: rem(10px) 0;
						color: $light;

						@include breakpoint(small-between) {
							font-size: rem(14px);
						}

						@include breakpoint(large) {
							font-size: rem(15px);
						}

						@include breakpoint(giant-between) {
							padding: 0 rem(20px) 0 rem(70px);
						}

						&:before {
							display: inline-block;
							content: '';
							background-image: url("../images/check.svg");
							background-size: cover;
							height: rem(25px);
							width: rem(25px);
							margin-bottom: rem(-7px);
							margin-right: rem(15px);
						}

					}
				}
			}
		}
		.content {
			h2,.h2 {
				font-size: rem(24px);
				line-height: rem(35px);
				@include breakpoint(large) {
					font-size: rem(34px);
					line-height: rem(45px);
				}
			}
			h3,.h3 {
				font-size: rem(19px);
				line-height: rem(30px);
				@include breakpoint(large) {
					font-size: rem(28px);
					line-height: rem(45px);
				}
			}
			p:last-child {
				@include breakpoint(small-between) {
					margin-bottom: 0;
				}
			}
		}
	}

	.staff-container {
		margin-bottom: 0;
		margin-top: rem(60px);
		@include breakpoint(tiny, max) {
			max-width: rem(280px);
		}

		@include breakpoint(tiny) {
			padding: {
				top: rem(100px);
				left: 22%;
				right: 22%;
				bottom: 0;
			}
		}

		@include breakpoint(small) {
			padding: {
				top: rem(100px);
				left: 30%;
				right: 30%;
				bottom: 0;
			}
		}

		@include breakpoint(medium) {
			padding: {
				top: rem(100px);
				left: 37%;
				right: 37%;
				bottom: 0;
			}
		}

		@include breakpoint(medium) {
			margin-top: rem(100px);
			padding: {
				top: rem(100px);
				left: 37%;
				right: 37%;
				bottom: 0;
			}
		}

		@include breakpoint(large) {
			margin-top: rem(150px);
			padding: {
				top: rem(100px);
				left: 15.5%;
				right: 15.5%;
				bottom: 0;
			}
		}

		@include breakpoint(giant) {
			padding: {
				top: rem(100px);
				left: 20%;
				right: 20%;
				bottom: 0;
			}
		}

		@include breakpoint(giant-between) {
			padding: {
				top: rem(100px);
				left: 22%;
				right: 22%;
				bottom: 0;
			}
		}

		@include breakpoint(huge) {
			padding: {
				top: rem(100px);
				left: 27%;
				right: 27%;
				bottom: 0;
			}
		}


		&:before {
			background: #f0f0f0;
			content: '';
			width: 100%;
			height: rem(333px);
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
		}

		// @include breakpoint(large) {
		// 	margin-top: rem(150px);
		// }

		&.swiper-container {

			.button-next,
			.button-prev {
				display: none;
				position: absolute;
				top: 50%;
				z-index: 50;
				cursor: pointer;
				opacity: 0.8;
				transition: 0.3s linear all;

				svg {
					width: rem(55px);
					height: rem(30px);
				}

				@include breakpoint(medium) {
					display: block;
				}

				&:hover,&:focus {
					transform: scale(1.05);
					opacity: 1;
				}
			}

			.button-next {
				right: rem(30px);

				@include breakpoint(giant) {
					right: 12%;
				}

				@include breakpoint(giant-between) {
					right: 16%;
				}

			}

			.button-prev {
				left: rem(30px);
				transform: rotate(180deg);
				
				&:hover {
					transform: scale(1.05) rotate(180deg);
				}
				@include breakpoint(giant) {
					left: 12%;
				}
				@include breakpoint(giant-between) {
					left: 16%;
				}
			}

			figure:not(.swiper-slide-active) {
				&:not(.swiper-slide-prev):not(.swiper-slide-next) {
					opacity: 0;
					visibility: hidden;
				}

			}
		}

		.staff {

			figure {
				display: block;
				background: #dbdbdb;
				// width: 264px;
				// height: 264px;

				@include breakpoint(large) {
					background: transparent;
					img {
						width: 264px;
						height: 264px;
						display: block;
						margin: 0 auto;
						object-fit: cover;
					}
				}


				figcaption {
					background: $light;
					display: block;
					text-decoration: none;
					color: #797272;
					z-index: 500;
					padding: rem(30px) rem(15px) 0 rem(15px);
					height: auto;
					font-size: rem(17px);
					font-weight: 400;
					line-height: rem(30px);
					transition: all 1s;
					text-align: center;
					width:auto;

					@include breakpoint(medium){
						padding: rem(30px) rem(15px);
					}
				}
			}
		}
	}

}

// FOOTER
//////////////////////////////

footer {
	// margin-bottom: rem(50px);

	@include breakpoint(large) {
		// margin-bottom: rem(100px);
	}

	.container-maps {
		line-height: 0;
		// @include spacing-small();
		@include breakpoint(large) {
			// margin-top: 0;
			// margin-bottom: rem(25px);
		}

		iframe {
			width: 100%;
			height:rem(300px); 
			border: 0;

			@include breakpoint(large){
				height:rem(450px);
			}
		}
	}
	.informations {
		text-align:center;
		margin-bottom: rem(60px);
		@include breakpoint(small){
			text-align: left;
		}
		@include breakpoint (medium){
			margin-bottom: 4.5rem;
		}
		.info-col{
			@include breakpoint(small, max) {
				margin-bottom: rem(20px);
			}
		}
		h3 {
			// margin-top: rem($base-gap);
		}
		span ,a{
			font-size: rem(22px);
			margin-top: rem(18px);
			margin-bottom: rem($base-gap);

			@include breakpoint(small) {
				// margin-top: rem(15px);
				margin-bottom: rem(15px);
			}

		}


		.loc {

			margin-top: 0;
			@include breakpoint(tiny){
				margin-top: rem(18px);
			}
		}

		p,a {
			color: #797272;
			margin-bottom: 0;
			font-size:rem(15px);
			line-height: rem(24px);
			text-decoration: none;

			@include breakpoint(tiny){
				font-size:rem(17px);
				line-height: rem(30px);
			}

			@include breakpoint(small){
				font-size:rem(16px);
				line-height: rem(25px);
			}

			@include breakpoint(medium){
				font-size:rem(17px);
				line-height: rem(30px);
			}
		}

		ul.naviadd {
			li {
				a {
					font-weight: 700;
					font-size: rem(17px);
					letter-spacing: rem(1px);
					text-transform: uppercase;
					text-decoration: none;
					color: $add;
					// margin-top: rem(40px);
					display: inline-block;	
					border-bottom: 1px solid $light;
					transition: 0.3s linear all;

					@include breakpoint(medium){
						// margin-top: rem(75px);
					}

					&:hover, &.active {
						color: $add;
						border-bottom: 1px solid $add;
					}
				}
			}

		}
		aside {
			a {
				background-image: image-url('sprite_social.png');
				background-repeat: no-repeat;
				width: rem(40px);
				height: rem(40px);
				display: inline-block;
				text-indent: -9999px;
				margin-right: rem($base-gap);
				transition: 0.5s all;
				color:$dark;

				@include breakpoint(small) {
					margin-right: rem(10px);
					// margin-bottom: rem($base-gap);
					margin-top: rem(35px);
				}

				&:hover, &:focus {
					transform: scale(1.1);
				}

				&.twitter {
					background-position: 0 0px;
				}
				&.instagram {
					background-position: rem(-53px) 0px;
				}
				&.linkedin {
					background-position: rem(-106px) 0;
					margin-right: 0;
				}
			}
		}
	}
}


.fixedBtn{
	position: fixed;
	display:flex;
	justify-content: center;
	align-items: center;
	z-index: 100000;
	background-color: $add;
	color: $light;
	right: rem(-24px);
	top:rem(244px);
	transform: rotate(90deg);
	text-decoration:none;
	text-transform:uppercase;
	transition: all 0.3s;
	font-size:rem(13px);

	@include breakpoint(large){
		right: rem(-17px);
	}
	
	span{
		display: block;
		animation: zoomText 1s 5s infinite;
		position: relative;
		padding: rem(15px) rem(20px) rem(20px) rem(45px);

		&:before{
			@extend .fa-solid;
			@extend .fa-file-pdf;
			position: absolute;
			left: rem(18px);
			font-size: rem(18px);
			@include breakpoint(large){
				font-size: rem(22px);
			}
		}
	}

	

	@include breakpoint(medium){
	
	}

	@include breakpoint(large){
		font-size: rem(16px);
	}

	&:hover,&:focus {
		background: rgba(#46a9d2, 0.9);
		color: $light;
		transform: rotate(90deg) scale(1.1);
	}

	body:not(.index) &{
		display: none;
	}
}


@keyframes zoomText {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}

.phone-btn {
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-align-content: space-between;
	-ms-flex-line-pack: justify;
	align-content: space-between;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background: $add;
	display: inline-block;
	padding:rem(12px) rem(22px);
	// margin-bottom: rem(5px);
	text-align: center;
	
	svg {
		display: inline-block;
		width: rem(18px);
		height: rem(18px);
		z-index: 1;
		overflow: visible;
		margin: { top: rem(2px); right: rem(5px); bottom: rem(-3px); }
		color: $primary;
		color: $light;
		-webkit-transform: rotate(100deg);
		-moz-transform: rotate(100deg);
		-o-transform: rotate(100deg);
		-ms-transform: rotate(100deg);
		transform: rotate(100deg);
		filter: progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=0, M21=0, M22=-1, DX=0, DY=0, SizingMethod='auto expand');
	}
	a,span {
		font-weight: 700;
		text-decoration: none;
		color: $light;
		font-size: rem(15px);
	}
}
