// Relativ path to images folder (used in the image-url() function)
$image-url-path: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$base-font-size: 16px;

// Line height of main content (in px)
$base-line-height: 27px;

// Headline font sizes (in px)
$h1-size: 35px;
$h2-size: 32px;
$h3-size: 27px;
$h4-size: 22px;
$h5-size: 18px;
$h6-size: 16px;

// Font stacks: $main-font for simple content, $display-font for headlines etc., $icon-font for icons
$main-font:    "Lato", sans-serif;
$display-font: "Lato", sans-serif;
$icon-font:    "FontAwesome";
// $icon-font2:   "icomoon";


// +++++ Design & Layout
// Colours for your design.
$primary:   #46a9d2;
$add: #257DA2;
$secondary:	#151516;
$light:     #fff;
$medium:    #f1f1f1;
$dark:      #2a2a2a;
$border:    #bbb;
$alert:     #D85D00;
$mobile-bar: #eaeae9;
$mobile-bar-link-default: #c9dfe9 ;

// Horizontal padding left and right of grid columns
$base-gap: 18px;

// Grid row max-width
$row-max-width: 1500px;

// Standard border for your design
$base-border: 1px solid $border;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bp-context: 16px;

// Breakpoints for min-width media queries (used in _responsive.scss and _grid.scss)
$breakpoints: (
	'minimum':     em(320px, $bp-context),
    'tiny':     em(480px, $bp-context),
    'small' : em(640px, $bp-context),
    'small-between' : em(740px, $bp-context),
    'medium':   em(800px, $bp-context),
    'large':    em(992px, $bp-context),
    'giant' : em(1200px, $bp-context),
    'giant-between' : em(1400px, $bp-context),
    'huge':     em(1600px, $bp-context)
);


 // +++++ Miscellaneous
// text-indent value when using hide-text(): "right" = positive value, "left" = negative value
$hide-text-direction: "right";

// +++++ The following settings can be left alone in most cases
// Column selector names
$column-names: "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12";
