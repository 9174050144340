nav {
	&.mobil {
	    width: 100%;
	    text-align: left;
	    display: inline-block;

	    .floatList {
	    	padding: $base-gap;
            color: $dark;
            font-size: rem(12px);

            @include breakpoint(tiny) {
            	float: left;
            	font-size: rem(14px);
            }


	    	dt {
	    		width: 100%;
	    		font-weight: 900;
	    		text-transform: uppercase;

	    		&:not(:first-child), &:not(:nth-child(2)) {
	    			width: 30%;
	    			float: left;
	    		}
	    	}

	    	dd {
	    		width: 100%;
	    		margin-bottom: rem(12px);

	    		&:not(:first-child), &:not(:nth-child(2)) {
	    			width: 70%;
	    			float: left;
	    		}

	    		p {
	    			margin-bottom: 0;
	    			display:block;
	    		}

	    		a {
	    			transition: color .3s ease;
	    			color: $dark;
	    		}
	    	}
	    }

		li {
			a, span {
				color:$dark;
				font-family:$main-font;
				font-weight: 400;
				font-size: rem(12px);
				display: inline-block;
				width: 100%;
				transition:.35s all ease;
				text-decoration: none;
				padding: rem(13px) 0;
				border-bottom: .0625rem solid rgba(5, 44, 60, 0.2);
				background-color: $mobile-bar-link-default;
				padding-left: $base-gap;

				@include breakpoint(small) {
					font-size: rem(14px);
				}
			
				&:hover {
					color:$light;
					background: $primary;

					&:after {
						color:$light;
					}
				}

				&.active {
					color:$light;
					background: $primary;

					&:after {
						color:$light;
					}
				}

			}

			&.active {
				> a,span {
					background: $primary;

					&:after {
						color:$light;
					}
				}
			}	

			&.hasSub {
				border-bottom: rem(1px) solid rgba($primary, .2);

				> a, span {
					position: relative;
					border: none;

					&:before {
	                    @extend .fa-solid;
	                    @extend .fa-angle-down;
	                    
	                    position: absolute;
	                    top: 50%; 
	                    right: rem(16px);
	                    transform: translateY(-70%);

	                    transition: all 350ms ease;
	                }
				}
			}	
		}

		.sub {
			border-bottom: .0625rem solid rgba(5, 44, 60, 0.2);
			a {
				// background-color: darken($mobile-bar-link-default,5%);
				padding-left: rem($base-gap * 2);
				position: relative;

				&:hover, &.active,&:focus {
					background-color: $primary;
					color:$light;
				}
			}

			li {

				a {
					border-bottom: none;

					&:before {
						content: "";
						display: inline-block;
						position: absolute;
						left: rem($base-gap);
						top: 0;
						bottom: 0;
						width: rem(1px);
						background: rgba($dark, .2);
					}
					
				}
			}
		}
	}
}

.page-navi {
	background:$mobile-bar;
	left:0;
	overflow-y: auto;
	position: fixed;
	transition:.6s all ease-in-out;
	width:rem(280px);
	z-index: 1001;
    top: 0;
    max-width:100%;
    height: 100%;
    transform:translate3d(-100%,0,0);
    visibility: hidden;
    padding-bottom: rem(100px);

    @include breakpoint(tiny) {
    	width: rem(340px);
    }

    @include breakpoint(small) {
    	width: rem(380px);
    }

    @include breakpoint(large){
    	padding-bottom:0
    }

	.nojs & {
		transform:translate3d(-100%,0,0);
		transition:none;
	}

	@include breakpoint(large) {
		display: none;
	}


	aside.images {
		position: relative;
		width:100%;
		height: rem(200px);
		background: #309fcd;

		figure {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			width:100%;
			height:100%;
			overflow: hidden;
			text-align:center;

			&:not(:first-child) {
				display: none;
			}

			img {
				max-width: unset;
				width: unset;
				height:100%;
			}
		}
	}

	&::-webkit-scrollbar {
	    width: rem(8px);
	    background-color: $secondary;
	}
	   
	&::-webkit-scrollbar-thumb {
		background-color: $primary;
		outline: none;
	}
}

.mobile-bar {
	align-items:center;
	background:$mobile-bar;

	display: flex;
	left:0;
	position: fixed;
	right:0;
	top:0;
	z-index: 1000;
	height: rem(100px);
	transition: all .3s ease;

	@include breakpoint(large) {
		display: none;
	}

	&.nav-up {
		transform: translateY(-120%);
	}

	&.nav-down {
		transform: translateY(0);
	}

	li {
		display:flex;
		flex-grow:1;
		padding:0 rem($base-gap);
		align-items: center;

		&:last-child {
        	justify-content:flex-end;    
        }

        &.phone {
        	svg {
	        	display: inline-block;
	        	// width: rem(25px);
	        	// height: rem(25px);
	        	width: rem(22px);
	        	height: rem(22px);
	        	z-index: 1;
	        	overflow: visible;
	        	margin-top: rem(10px);
	        	color: darken($primary,25%);
	        	-webkit-transform: rotate(100deg);
	        	-moz-transform: rotate(100deg);
	        	-o-transform: rotate(100deg);
	        	-ms-transform: rotate(100deg);
	        	transform: rotate(100deg);
	        	filter: progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=0, M21=0, M22=-1, DX=0, DY=0, SizingMethod='auto expand');

	        	&.mail{
	        		transform: none;
	        		transform: scale(1.2)
	        	}
	        }
	        > span, > a{
	        	margin-right: rem(16px);
	        }
	        .phoneLink-icon{
	        	top:rem(1px);
	        	position: relative;
	        }
	        a{
	        	&.email{
	        		width: 27px;
	        		height: 27px;
	        		background-image: image-url('mobile-menu/email.svg');
	        		background-size: cover;
	        		background-repeat: no-repeat;
	        		margin-right:0;
	        	}
	        }

        }
        &.branding {
        	a {
        		// display: block;
        		// transform: scale(1);
        		// transform-origin:top center;
        		// z-index: 0;
        		// transition:350ms all ease-in-out;
        		// text-align: center;
				margin: auto;
				
        		img {
        			width: auto;
        			max-width: 100%;
        			max-height: rem(70px);
        		}        		
        	}
        }
	}

}

label[for="navi-toggled"] {
    position: relative;
	display: flex;
	width: rem(25px);
	height: rem(25px);
	flex-direction:row-reverse;
    cursor: pointer;

	.bars {
		width: rem(25px);
    	height: rem(2px);
		background-color: darken($primary, 25%);
		transition: all 0.5s 300ms;
		position: absolute;
    	top: 50%;
    	right:0;
    	font-size: 0;
		display: inline-block;
		overflow: visible;

		@include breakpoint(small) {
			width: rem(25px);
			height: rem(2px);
		}


		&::before, &:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: rem(25px);
			height: rem(2px);
			background-color: inherit;
			background-color: darken($primary, 25%);
			transform: translateZ(0);
			backface-visibility: hidden;
			transition: 0.5s all ease-in-out 300ms;
		
		}

		&::before {
			transform-origin: right top;
			transform: translateY(-6px);
		}

		&::after {
			transform-origin: right bottom;
			transform: translateY(6px);
		}
	}

}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			
			.bars {

				background-color: transparent;


				&:after, 
				&:before {
					width:100%;
					transition: all 0.5s 300ms;
					background-color: red;
					width: rem(35px);
					height: rem(3px);

					@include breakpoint(small) {
						width: rem(25px);
						height: rem(2px);
						background-color: darken($primary, 25%);
					}

				}

				&:before {
					transform: rotate(45deg);
					transform-origin: center bottom;
				}

				&:after {
					transform: rotate(-45deg);
					transform-origin: center top;
				}
			}

			&:after {
				opacity: 1;
				pointer-events:auto;
			}
		}

		~ * .branding {
			z-index: -1;
		}

		~ * .page-navi,
		~ .page-navi {
			transform:translate3d(0,0,0);
			visibility: visible;
		}

		~ .mobile-bar {
			transform: translateY(0)!important;
		}
	}
}



.desktop-bar .navi-main {
		> li {
			float:left;
			position: relative;

			&.hasSub {
				&:hover, &:focus,&:active {
					.sub {
						opacity: 1;
						visibility: visible;
					}
				}

				&.active, &:hover {
					a, span {
						color:$dark;
						&:before{
							opacity: 1;
							visibility: visible;
							width: 100%;
						}
					}
				}
			}

			.sub {
				opacity: 0;
				visibility: hidden;
				position: absolute;
				top: rem(40px);
				left: 0;
				width:rem(330px);
				z-index: 1000;
				color:#2e3a3f;
				border-left: rem(1px) solid $primary;
				
				@include breakpoint(large){
					left: 50%;
					transform: translateX(-50%);

				}
				body.javascript &{
					transition:0.3s linear all;
				}
				li {
					width:100%;
					display:block;
					background:$light;
					

					&.active {
						border-left: rem(5px) solid $primary;
					}
					
					a {
						width:100%;
						font-size: rem(14px);
						padding: rem(20px) rem(20px);
						margin-right: 0;
						display: block;
						color:#2e3a3f !important;
						border-left: rem(4px) solid transparent;
						transition: 0.3s linear all;
						&:before{
							display: none;
						}
						&:hover, &:focus, &.active {
							border-left: rem(4px) solid $primary;
							color: $primary;
							padding: rem(20px) rem(20px) rem(20px) rem(24px);
						}
					}
				}
			}
	
			&.contact {
				a, span { margin-right: 0; }
			}

			a, span {
				font-weight: 700;
				font-size: rem(14px);
				text-transform: uppercase;
				text-decoration: none;
				transition: all 0.5s;
				color: #2e3a3f;
				margin-right: rem(25px);
				padding: rem(20px) 0;
				position: relative;

				@include breakpoint(giant){
					font-size: rem(17px);
					margin-right: rem(35px);
				}
				&:before{
					position: absolute;
					content: "";
					width: 0;
					height: 1px;
					background: $add;
					opacity: 0;
					visibility: hidden;
					left:0;
					bottom:rem(10px);
					transition:0.3s linear opacity, 0.3s linear visibility, 0.3s linear width;
				}
				&:hover, &:focus, &.active {
					color:$add;
					&:before{
						opacity: 1;
						visibility: visible;
						width: 100%;
					}
				}

				&.services{
					&:after{
						@extend .fa-solid;
						content: '\f107';
						margin-left: rem(3px);
					}
				}
			}
		}	
	}