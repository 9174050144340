@mixin spacing() {
	margin-top: rem(60px);
	margin-bottom: rem(60px);

	@include breakpoint(medium) {
		margin-top: rem(100px);
		margin-bottom: rem(100px);		
	}
}


@mixin spacing-small() {
	margin-top: rem(25px);
	margin-bottom: rem(25px);

	@include breakpoint(large) {
		margin-top: rem(50px);
		margin-bottom: rem(50px);		
	}
}


@mixin spacing-small-inner() {
	padding-top: rem(25px);
	padding-bottom: rem(25px);

	@include breakpoint(large) {
		padding-top: rem(50px);
		padding-bottom: rem(50px);		
	}
}
